const Bloco7 = `
<svg viewBox="-2 -8 119 119">
 <g>
  <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" cx="14" cy="52" r="9" vector-effect="non-scaling-stroke"/>
  <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" cx="102" cy="52" r="9" vector-effect="non-scaling-stroke"/>
  <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" cx="58" cy="52" r="9" vector-effect="non-scaling-stroke"/>
  <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" cx="36" cy="90" r="9" vector-effect="non-scaling-stroke"/>
  <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" cx="36" cy="14" r="9" vector-effect="non-scaling-stroke"/>
  <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" cx="80" cy="90" r="9" vector-effect="non-scaling-stroke"/>
  <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" cx="80" cy="14" r="9" vector-effect="non-scaling-stroke"/>
  <rect fill="none" stroke="#373435" stroke-width="1" width="115" height="103" vector-effect="non-scaling-stroke"/>
 </g>
</svg>
`;
export default Bloco7;
