const Bloco16 = `
<svg viewBox="-2 -2 28.44 28.44">
  <g>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" vector-effect="non-scaling-stroke" cx="2.08" cy="2.08" r="1.36"/>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" vector-effect="non-scaling-stroke" transform="matrix(3.2083E-14 -1.21139 1.21139 3.2083E-14 8.8731 8.8731)" r="1.12"/>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" vector-effect="non-scaling-stroke" cx="8.87" cy="2.08" r="1.36"/>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" vector-effect="non-scaling-stroke" transform="matrix(3.2252E-14 -1.21139 1.21139 3.2252E-14 15.6617 8.87311)" r="1.12"/>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" vector-effect="non-scaling-stroke" transform="matrix(3.2083E-14 1.21139 -1.21139 3.2083E-14 2.08448 8.8731)" r="1.12"/>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" vector-effect="non-scaling-stroke" cx="15.66" cy="2.08" r="1.36"/>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" vector-effect="non-scaling-stroke" transform="matrix(3.2083E-14 -1.21139 1.21139 3.2083E-14 8.8731 15.6617)" r="1.12"/>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" vector-effect="non-scaling-stroke" transform="matrix(3.2252E-14 -1.21139 1.21139 3.2252E-14 15.6617 15.6617)" r="1.12"/>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" vector-effect="non-scaling-stroke" transform="matrix(3.2083E-14 1.21139 -1.21139 3.2083E-14 2.08448 15.6617)" r="1.12"/>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" vector-effect="non-scaling-stroke" transform="matrix(3.2252E-14 -1.21139 1.21139 3.2252E-14 22.4504 8.87311)" r="1.12"/>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" vector-effect="non-scaling-stroke" cx="22.45" cy="2.08" r="1.36"/>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" vector-effect="non-scaling-stroke" transform="matrix(3.2252E-14 -1.21139 1.21139 3.2252E-14 22.4504 15.6617)" r="1.12"/>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" vector-effect="non-scaling-stroke" transform="matrix(3.2083E-14 -1.21139 1.21139 3.2083E-14 8.8731 15.6617)" r="1.12"/>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" vector-effect="non-scaling-stroke" transform="matrix(3.2252E-14 -1.21139 1.21139 3.2252E-14 15.6617 15.6617)" r="1.12"/>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" vector-effect="non-scaling-stroke" transform="matrix(3.2083E-14 1.21139 -1.21139 3.2083E-14 2.08448 15.6617)" r="1.12"/>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" vector-effect="non-scaling-stroke" transform="matrix(3.2083E-14 -1.21139 1.21139 3.2083E-14 8.8731 22.4503)" r="1.12"/>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" vector-effect="non-scaling-stroke" transform="matrix(3.2252E-14 -1.21139 1.21139 3.2252E-14 15.6617 22.4503)" r="1.12"/>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" vector-effect="non-scaling-stroke" transform="matrix(3.2083E-14 1.21139 -1.21139 3.2083E-14 2.08448 22.4503)" r="1.12"/>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" vector-effect="non-scaling-stroke" transform="matrix(3.2252E-14 -1.21139 1.21139 3.2252E-14 22.4504 15.6617)" r="1.12"/>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" vector-effect="non-scaling-stroke" transform="matrix(3.2252E-14 -1.21139 1.21139 3.2252E-14 22.4504 22.4503)" r="1.12"/>
    <rect fill="none" stroke="#373435" stroke-width="1" vector-effect="non-scaling-stroke" width="24.44" height="24.44"/>
  </g>
</svg>
`;
export default Bloco16;
