const Bloco51 = `
<svg viewBox="-2 -2 31 31">
 <g>
  <rect fill="none" stroke="#373435" stroke-width="1" width="27" height="27" vector-effect="non-scaling-stroke"/>
  <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" cx="5" cy="5" r="3" vector-effect="non-scaling-stroke"/>
  <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" transform="matrix(3.2083E-14 -1 1 3.2083E-14 21.8066 21.8066)" r="3" vector-effect="non-scaling-stroke"/>
  <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" cx="22" cy="5" r="3" vector-effect="non-scaling-stroke"/>
  <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" transform="matrix(3.2083E-14 1 -1 3.2083E-14 5.12285 21.8066)" r="3" vector-effect="non-scaling-stroke"/>
  <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" cx="13" cy="13" r="3" vector-effect="non-scaling-stroke"/>
  <rect fill="none" stroke="#373435" stroke-width="1" width="27" height="27" vector-effect="non-scaling-stroke"/>
 </g>
</svg>
`;
export default Bloco51;
