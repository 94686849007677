const Bloco4 = `
<svg viewBox="3 3 1051 1051">
 <g>
  <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" cx="201" cy="201" r="131" vector-effect="non-scaling-stroke"/>
  <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" transform="matrix(3.2083E-14 -1.21139 1.21139 3.2083E-14 855.643 855.643)" r="108" vector-effect="non-scaling-stroke"/>
  <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" cx="856" cy="201" r="131" vector-effect="non-scaling-stroke"/>
  <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" transform="matrix(3.2083E-14 1.21139 -1.21139 3.2083E-14 201.009 855.643)" r="108" vector-effect="non-scaling-stroke"/>
  <rect fill="none" stroke="#373435" stroke-width="1" x="5" y="5" width="1047" height="1047" vector-effect="non-scaling-stroke"/>
  </g>
</svg>
`;
export default Bloco4;
