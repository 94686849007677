const Bloco12 = `
<svg viewBox="-2 -5.54 29.49 29.49">
  <g>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" vector-effect="non-scaling-stroke" cx="2.17" cy="2.17" r="1.42"/>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" vector-effect="non-scaling-stroke" transform="matrix(3.2083E-14 -1.21139 1.21139 3.2083E-14 9.25523 9.25523)" r="1.17"/>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" vector-effect="non-scaling-stroke" cx="9.26" cy="2.17" r="1.42"/>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" vector-effect="non-scaling-stroke" transform="matrix(3.2252E-14 -1.21139 1.21139 3.2252E-14 16.3362 9.25525)" r="1.17"/>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" vector-effect="non-scaling-stroke" transform="matrix(3.2083E-14 1.21139 -1.21139 3.2083E-14 2.17426 9.25523)" r="1.17"/>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" vector-effect="non-scaling-stroke" cx="16.34" cy="2.17" r="1.42"/>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" vector-effect="non-scaling-stroke" transform="matrix(3.2083E-14 -1.21139 1.21139 3.2083E-14 9.25523 16.3362)" r="1.17"/>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" vector-effect="non-scaling-stroke" transform="matrix(3.2252E-14 -1.21139 1.21139 3.2252E-14 16.3362 16.3362)" r="1.17"/>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" vector-effect="non-scaling-stroke" transform="matrix(3.2083E-14 1.21139 -1.21139 3.2083E-14 2.17426 16.3362)" r="1.17"/>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" vector-effect="non-scaling-stroke" transform="matrix(3.2252E-14 -1.21139 1.21139 3.2252E-14 23.4172 9.25525)" r="1.17"/>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" vector-effect="non-scaling-stroke" cx="23.42" cy="2.17" r="1.42"/>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" vector-effect="non-scaling-stroke" transform="matrix(3.2252E-14 -1.21139 1.21139 3.2252E-14 23.4172 16.3362)" r="1.17"/>
    <rect fill="none" stroke="#373435" stroke-width="1" vector-effect="non-scaling-stroke" x="0" y="0" width="25.49" height="18.41"/>
  </g>
</svg>
`;
export default Bloco12;
