const Bloco3 = `<svg viewBox="-2 -32 433 433">
 <g>
  <g >
   <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" stroke-miterlimit="22.9256" cx="89" cy="296" r="50" vector-effect="non-scaling-stroke"/>
   <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" stroke-miterlimit="22.9256" cx="341" cy="296" r="50" vector-effect="non-scaling-stroke"/>
   <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" stroke-miterlimit="22.9256" cx="215" cy="77" r="50" vector-effect="non-scaling-stroke"/>
   <polygon fill="none" stroke="#373435" stroke-width="1" stroke-miterlimit="22.9256" points="46,371 385,371 429,296 259,2 172,2 2,296 " vector-effect="non-scaling-stroke"/>
  </g>
 </g>
</svg>
`;
export default Bloco3;
