import Bloco1 from "./bloco1";
import Bloco2 from "./bloco2";
import Bloco3 from "./bloco3";
import Bloco31 from "./bloco31";
import Bloco4 from "./bloco4";
import Bloco5 from "./bloco5";
import Bloco51 from "./bloco51";
import Bloco6 from "./bloco6";
import Bloco7 from "./bloco7";
import Bloco8 from "./bloco8";
import Bloco81 from "./bloco81";
import Bloco9 from "./bloco9";
import Bloco10 from "./bloco10";
import Bloco12 from "./bloco12";
import Bloco14 from "./bloco14";
import Bloco15 from "./bloco15";
import Bloco16 from "./bloco16";
const Modelos = [
  { svg: Bloco1, modelo: 301 },
  { svg: Bloco2, modelo: 302 },
  { svg: Bloco3, modelo: 303 },
  { svg: Bloco31, modelo: 303.1 },
  { svg: Bloco4, modelo: 304 },
  { svg: Bloco5, modelo: 305 },
  { svg: Bloco51, modelo: 305.1 },
  { svg: Bloco6, modelo: 306 },
  { svg: Bloco7, modelo: 307 },
  { svg: Bloco8, modelo: 308 },
  { svg: Bloco81, modelo: 308.1 },
  { svg: Bloco9, modelo: 309 },
  { svg: Bloco10, modelo: 310 },
  { svg: Bloco12, modelo: 311 },
  { svg: Bloco14, modelo: 314 },
  { svg: Bloco15, modelo: 315 },
  { svg: Bloco16, modelo: 316 },
]

export default Modelos;
