const Bloco5 = `
<svg viewBox="-2 -33 141 141">
 <g>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" vector-effect="non-scaling-stroke" cx="18" cy="16" r="10"/>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" vector-effect="non-scaling-stroke" cx="119" cy="16" r="10"/>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" vector-effect="non-scaling-stroke" cx="69" cy="16" r="10"/>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" vector-effect="non-scaling-stroke" cx="43" cy="59" r="10"/>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" vector-effect="non-scaling-stroke" cx="94" cy="59" r="10"/>
    <polygon fill="none" stroke="#373435" stroke-width="1" vector-effect="non-scaling-stroke" points="9,0 128,0 137,16 103,75 35,75 0,16 "/>
 </g>
</svg>
`;
export default Bloco5;
