const Bloco81 = `
<svg viewBox="-2 -31 108 108">
  <g>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" vector-effect="non-scaling-stroke" cx="8.88" cy="8.88" r="5.78"/>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" vector-effect="non-scaling-stroke" transform="matrix(3.2083E-14 -1.21139 1.21139 3.2083E-14 37.7809 37.7809)" r="4.77"/>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" vector-effect="non-scaling-stroke" cx="37.78" cy="8.88" r="5.78"/>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" vector-effect="non-scaling-stroke" transform="matrix(3.2252E-14 -1.21139 1.21139 3.2252E-14 66.6862 37.7809)" r="4.77"/>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" vector-effect="non-scaling-stroke" transform="matrix(3.2083E-14 1.21139 -1.21139 3.2083E-14 8.87555 37.7809)" r="4.77"/>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" vector-effect="non-scaling-stroke" cx="66.69" cy="8.88" r="5.78"/>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" vector-effect="non-scaling-stroke" transform="matrix(3.2252E-14 -1.21139 1.21139 3.2252E-14 95.5916 37.7809)" r="4.77"/>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" vector-effect="non-scaling-stroke" cx="95.59" cy="8.88" r="5.78"/>
    <rect fill="none" stroke="#373435" stroke-width="1" vector-effect="non-scaling-stroke" width="104" height="46.25"/>
  </g>
</svg>
`;
export default Bloco81;
