<template>
  <CModal :show.sync="show" size="lg" addContentClasses="card" :closeOnBackdrop="false">
    <template slot="header-wrapper">
      <CCardHeader>
        <CIcon name="cil-applications" />&nbsp;Modelo
        <CButtonClose buttonClasses="text-muted close" @click="$emit('fechar-escolhe-modelo')" />
      </CCardHeader>
    </template>
    <template slot="body-wrapper">
      <CCardBody>
        <CRow>
          <CCol
            sm="2"
            v-html="bloco.svg"
            v-for="(bloco, index) in modelo"
            :key="index"
            class="modelo-blocos"
            @click="$emit('escolhe-modelo', bloco.modelo)"
          ></CCol>
        </CRow>
      </CCardBody>
    </template>
    <template slot="footer-wrapper">
      <CCardFooter>
        <CButton color="warning" size="sm" class="float-left">
          <CIcon name="cil-close"></CIcon>&nbsp;Fechar
        </CButton>
      </CCardFooter>
    </template>
  </CModal>
</template>
<script>
import Modelos from "./Modelos/modelos";
export default {
  name: "BlocoModelos",
  props: {
    show: { type: Boolean, default: false }
  },
  data() {
    return {
      modelo: Modelos
    };
  },
  methods: {}
};
</script>
<style scoped>
.modelo-blocos:hover >>> svg {
  background: rgba(0, 0, 0, 0.5);
}
.modelo-blocos {
  padding: 4px;
  cursor: pointer;
}
.modelo-blocos >>> svg {
  background: white;
  width: 100%;
  height: auto;
}
</style>
