<template>
  <div>
    <CCard>
      <CCardHeader>
        <CIcon name="cil-" />
      </CCardHeader>
      <CCardBody>
        <loader :loading="requisicaoAberta"></loader>
        <CDataTable :fields="campos" :items="blocos" :sorterValue="{column: 'id', asc: 'true'}">
          <td slot="id" slot-scope="{item, index}" class="text-center">{{index+1}}</td>
          <td slot="acoes" slot-scope="{item}">
            <CButtonGroup size="sm">
              <CButton color="danger">
                <CIcon name="cil-trash" />
              </CButton>
              <CButton color="info">
                <CIcon name="cil-folder-open" />
              </CButton>
              <CButton color="info" @click="abrirDesenhoBloco(item.id)">
                <CIcon name="cil-image1" />
              </CButton>
            </CButtonGroup>
          </td>
        </CDataTable>
      </CCardBody>
    </CCard>
    <BlocoVisualizacao :show.sync="blocoVisualizacao"></BlocoVisualizacao>
  </div>
</template>

<script>
//import Meta from "./../../../shared/meta-dados";
import mixin from "./../../../shared/mixin";
import mixinEstrutural from "./../mixinsEstrutural";
import Loader from "@/views/Componentes/loader";
import Axios from "axios";
import BlocoVisualizacao from "./BlocoVisualizacao";

export default {
  name: "EstruturalBloco",
  components: { Loader, BlocoVisualizacao },
  mixins: [mixin, mixinEstrutural],
  data() {
    return {
      requisicaoAberta: false,
      blocoVisualizacao: false,
      fundacao: 3,
      titulo: "Bloco",
      blocos: [],
      campos: [
        { key: "id", label: "#" },
        { key: "identificacao", label: "Identificação" },
        { key: "largura_tabela", label: "A (m)" },
        { key: "altura_tabela", label: "B (m)" },
        { key: "altura", label: "Altura(m)" },
        { key: "numero_estaca", label: "Numero de Estacas" },
        { key: "cota_assentamento", label: "Profundidade (m)" },
        { key: "acoes", label: "Ações" }
      ]
    };
  },
  methods: {
    abrirDesenhoBloco(idBloco) {
      this.blocoVisualizacao = true;
      this.$eventoHub.$emit("exibirDesenho", idBloco);
    },
    fecharDesenhoBloco() {
      this.blocoVisualizacao = false;
    },

    obtenhaEstrutural() {
      let idProjeto = this.obtenhaIdProjeto();
      let idEstrutural = this.obtenhaIdEstrutural();
      this.requisicaoAberta = true;
      Axios.get("/projeto/" + idProjeto + "/estrutural/" + idEstrutural)
        .then(resposta => {
          if (resposta.data.retorno.fundacao !== this.fundacao) {
            this.$router.push({
              name: "Estrutural",
              params: {
                projetoID: idProjeto
              }
            });
          }
          this.blocos = resposta.data.retorno.fundacoes.map(bloco => {
            bloco["largura_tabela"] = bloco.angulo == 90 ? bloco.a : bloco.b;
            bloco["altura_tabela"] = bloco.angulo == 90 ? bloco.b : bloco.a;
            return bloco;
          });
          this.titulo = resposta.data.retorno.identificacao;
        })
        .catch(erro => {
          console.log(erro);
        })
        .finally(() => {
          this.requisicaoAberta = false;
        });
    }
  },
  created() {
    this.obtenhaEstrutural();
    this.$eventoHub.$on("fecharJanelaVisualizacao", this.fecharDesenhoBloco);
  },
  destroyed() {
    this.$eventoHub.$off("fecharJanelaVisualizacao", this.fecharDesenhoBloco);
  }
};
</script>

<style>
.tab-content .dropdown {
  display: none;
}
</style>
