<template>
  <CModal :show.sync="show" size="xl" addContentClasses="card" :closeOnBackdrop="false">
    <template slot="header-wrapper">
      <CCardHeader>
        <CIcon name="cil-image1" />
        {{titulo}}
        <CButtonClose @click="fecharVisualizacao()" buttonClasses="text-muted close" />
      </CCardHeader>
    </template>
    <template slot="body-wrapper">
      <CCardBody>
        <loader :loading="requisicaoAberta"></loader>
        <CRow>
          <CCol sm="9">
            <div ref="vizualizador" id="visualizadorSVG"></div>
          </CCol>
          <CCol sm="3" v-if="fundacaoAtiva.carregado">
            <loader :loading="requisicaoVerificao"></loader>
            <CTabs variant="tabs" :active-tab="guiaAtivo">
              <CTab>
                <div slot="title">
                  <CIcon name="cil-object-group" />
                </div>
                <CRow>
                  <CCol sm="12">
                    <p class="mt-2 mb-2">
                      <b>Dimensão em plantas</b>
                    </p>
                    <CInput
                      label="Comprimento (a)"
                      type="number"
                      min="0"
                      append="m"
                      :value.sync="fundacaoAtiva.a"
                    />
                    <CInput
                      label="Largura (b)"
                      type="number"
                      min="0"
                      append="m"
                      :value.sync="fundacaoAtiva.b"
                    />
                    <CInput
                      label="Espaçamento X"
                      type="number"
                      min="0"
                      append="m"
                      :value.sync="fundacaoAtiva.ex"
                    />
                    <CInput
                      label="Espaçamento Y"
                      type="number"
                      min="0"
                      append="m"
                      :value.sync="fundacaoAtiva.ey"
                    />
                    <CInput
                      label="Ângulo"
                      type="number"
                      min="0"
                      append="m"
                      :value.sync="fundacaoAtiva.angulo"
                    />
                    <p class="mt-2 mb-2">
                      <b>Altura</b>
                    </p>
                    <CInput
                      label="Altura"
                      type="number"
                      min="0"
                      append="m"
                      :value.sync="fundacaoAtiva.altura"
                    />
                    <CInput
                      label="Entrada Estaca"
                      type="number"
                      min="0"
                      append="m"
                      :value.sync="fundacaoAtiva.entrada"
                    />
                  </CCol>
                </CRow>
              </CTab>
              <CTab>
                <div slot="title">
                  <CIcon name="cil-ethernet" />
                </div>
                <CRow>
                  <CCol sm="12">
                    <div v-for="(item, index) in fundacaoAtiva.aco" :key="index">
                      <p class="mt-2 mb-2">
                        <b>N{{item.posicao}}</b>
                      </p>
                      <CInput
                        label="Quantidade"
                        type="number"
                        min="0"
                        v-model.number="fundacaoAtiva.aco[index].quantidade"
                      />
                      <CSelect
                        label="Bitola"
                        :options="bitolasCriterio"
                        :value.sync="fundacaoAtiva.aco[index].bitola"
                      ></CSelect>
                      <CInput
                        label="Espaçamento"
                        type="number"
                        min="0"
                        append="m"
                        v-model.number="fundacaoAtiva.aco[index].espacamento"
                      />
                    </div>
                  </CCol>
                </CRow>
              </CTab>
              <CDropdown color="defatult" :inNav="true">
                <CIcon slot="toggler-content" name="cil-applications-settings"></CIcon>
                <CDropdownItem @click="redefinirEstrutural">Redefinir</CDropdownItem>
                <CDropdownItem @click="modelos=true">Alterar Modelo</CDropdownItem>
                <CDropdownItem>Relatório</CDropdownItem>
              </CDropdown>
            </CTabs>
            <CRow>
              <CCol sm="12">
                <CButton color="info" size="sm" class="float-right" @click="salvarAlteracao">
                  <CIcon name="cil-save"></CIcon>&nbsp;Salvar
                </CButton>
                <CButton color="warning" size="sm" class="float-left" @click="verificarFundacao">
                  <CIcon name="cil-check"></CIcon>&nbsp;Verificar
                </CButton>
              </CCol>
            </CRow>
          </CCol>
        </CRow>
        <BlocoModelos
          :show="modelos"
          v-on:fechar-escolhe-modelo="modelos=false"
          v-on:escolhe-modelo="alterarModelo"
        ></BlocoModelos>
      </CCardBody>
    </template>
    <template slot="footer-wrapper">
      <CCardFooter>
        <CButton color="success" size="sm" class="float-left">
          <CIcon name="cil-save"></CIcon>&nbsp;Salvar DXF
        </CButton>
      </CCardFooter>
    </template>
  </CModal>
</template>
<script>
import mixinVisualizacao from "../mixinVizualizacao";
import BlocoModelos from "./BlocoModelos";
import Axios from "axios";
import Visualizacao from "@/Plugin/Desenho/visualizacao";
export default {
  name: "BlocoVisualizacao",
  components: { BlocoModelos },
  mixins: [mixinVisualizacao],
  data() {
    return {
      requisicaoAberta: false,
      requisicaoVerificao: false,
      titulo: "",
      guiaAtivo: 0,
      modelos: false
    };
  },
  methods: {
    alterarModelo(modelo) {
      this.modelos = false;
      this.requisicaoAberta = true;
      let fundacao = this.fundacaoAtiva;
      fundacao.modelo = modelo;
      let url = this.montarURLRequisicao(fundacao.id, "alterar-modelo-bloco");
      Axios.post(url, fundacao)
        .then(resposta => {
          let retorno = resposta.data.retorno;
          this.aplicarDadosFundacaoAtiva(retorno.fundacao);
          Visualizacao.ParseDesenho(retorno.desenho);
          Visualizacao.zoomExtend();
          this.$eventoHub.$emit("adicionarMensagemAviso", {
            mensagem: "O modelo da fundação foi alterado!",
            classe: "bg-success"
          });
        })
        .catch(erro => {
          console.log(erro);
          this.$eventoHub.$emit("adicionarMensagemAviso", {
            mensagem: `Não é possível alterar o modelo da fundação <br> ${erro.data.retorno}`,
            classe: "bg-danger"
          });
        })
        .finally(() => {
          this.requisicaoAberta = false;
        });
    }
  }
};
</script>
<style scoped>
#visualizadorSVG {
  height: 75vh;
}
.tab-content .tab-pane {
  height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
