const Bloco81 = `
<svg viewBox="-2 -2 36 36">
  <g>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" vector-effect="non-scaling-stroke" cx="4" cy="4" r="2.4"/>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" vector-effect="non-scaling-stroke" transform="matrix(3.2083E-14 -1 1 3.2083E-14 16.1198 16.1198)" r="2.4"/>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" vector-effect="non-scaling-stroke" cx="16" cy="4" r="2.4"/>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" vector-effect="non-scaling-stroke" transform="matrix(3.2252E-14 -1 1 3.2252E-14 28.4528 16.1198)" r="2.4"/>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" vector-effect="non-scaling-stroke" transform="matrix(3.2083E-14 1 -1 3.2083E-14 3.7869 16.1198)" r="2.4"/>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" vector-effect="non-scaling-stroke" cx="28" cy="4" r="2.4"/>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" vector-effect="non-scaling-stroke" transform="matrix(3.2083E-14 -1 1 3.2083E-14 16.1198 28.4527)" r="2.4"/>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" vector-effect="non-scaling-stroke" transform="matrix(3.2252E-14 -1 1 3.2252E-14 28.4528 28.4528)" r="2.4"/>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" vector-effect="non-scaling-stroke" transform="matrix(3.2083E-14 1 -1 3.2083E-14 3.7869 28.4527)" r="2.4"/>
    <rect fill="none" stroke="#373435" stroke-width="1" vector-effect="non-scaling-stroke" width="32" height="32"/>
  </g>
</svg>
`;
export default Bloco81;
