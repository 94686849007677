const Bloco5 = `
<svg viewBox="-2 -15 71 71">
 <g>
  <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" cx="8" cy="8" r="5" vector-effect="non-scaling-stroke"/>
  <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" transform="matrix(3.2083E-14 -1 1 3.2083E-14 33.7017 33.7017)" r="5" vector-effect="non-scaling-stroke"/>
  <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" cx="34" cy="8" r="5" vector-effect="non-scaling-stroke"/>
  <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" transform="matrix(3.2252E-14 -1 1 3.2252E-14 59.4863 33.7018)" r="5" vector-effect="non-scaling-stroke"/>
  <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" transform="matrix(3.2083E-14 1 -1 3.2083E-14 7.91728 33.7017)" r="5" vector-effect="non-scaling-stroke"/>
  <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" cx="59" cy="8" r="5" vector-effect="non-scaling-stroke"/>
  <rect fill="none" stroke="#373435" stroke-width="1" width="67" height="41" vector-effect="non-scaling-stroke"/>
  </g>
</svg>
`;
export default Bloco5;
