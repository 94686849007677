const Bloco31 = `
<svg viewBox="-2 -943 2450 2450"
 <g>
  <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1"  cx="282" cy="282" r="188" vector-effect="non-scaling-stroke"/>
  <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1"cx="2163" cy="282" r="188" vector-effect="non-scaling-stroke"/>
  <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" cx="1223" cy="282" r="188" vector-effect="non-scaling-stroke"/>
  <rect fill="none" stroke="#373435" stroke-width="1" width="2446" height="564" vector-effect="non-scaling-stroke"/>
 </g>
</svg>
`;
export default Bloco31;
