const Bloco8 = `
<svg viewBox="-2 -5.5 77 77">
  <g>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" cx="65" cy="9" r="6" vector-effect="non-scaling-stroke"/>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" cx="37" cy="9" r="6" vector-effect="non-scaling-stroke"/>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" cx="23" cy="33" r="6" vector-effect="non-scaling-stroke"/>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" transform="matrix(0.605694 1.04909 -1.04909 0.605694 8.66461 8.66461)" r="5" vector-effect="non-scaling-stroke"/>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" cx="51" cy="33" r="6" vector-effect="non-scaling-stroke"/>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" cx="65" cy="58" r="6" vector-effect="non-scaling-stroke"/>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" cx="37" cy="58" r="6" vector-effect="non-scaling-stroke"/>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" transform="matrix(0.605694 -1.04909 -1.04909 -0.605694 8.66461 57.5401)" r="5" vector-effect="non-scaling-stroke"/>
    <rect fill="none" stroke="#373435" stroke-width="1" width="73" height="66" vector-effect="non-scaling-stroke"/>
  </g>
</svg>
`;
export default Bloco8;
