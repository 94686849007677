const Bloco14 = `
<svg viewBox="-2 -2 1691.2 1691.2">
  <g>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" vector-effect="non-scaling-stroke" cx="144.25" cy="614.02" r="93.95"/>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" vector-effect="non-scaling-stroke" transform="matrix(3.2083E-14 -1.21139 1.21139 3.2083E-14 614.019 1083.79)" r="77.56"/>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" vector-effect="non-scaling-stroke" transform="matrix(3.2083E-14 1.21139 1.21139 -3.2083E-14 614.019 614.02)" r="77.56"/>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" vector-effect="non-scaling-stroke" transform="matrix(3.2252E-14 -1.21139 1.21139 3.2252E-14 1083.79 1083.79)" r="77.56"/>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" vector-effect="non-scaling-stroke" transform="matrix(3.2252E-14 1.21139 1.21139 -3.2252E-14 1083.79 614.019)" r="77.56"/>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" vector-effect="non-scaling-stroke" transform="matrix(3.2083E-14 1.21139 -1.21139 3.2083E-14 144.246 1083.79)" r="77.56"/>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" vector-effect="non-scaling-stroke" transform="matrix(3.2252E-14 -1.21139 1.21139 3.2252E-14 1553.57 1083.79)" r="77.56"/>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" vector-effect="non-scaling-stroke" transform="matrix(3.2252E-14 1.21139 1.21139 -3.2252E-14 1553.57 614.019)" r="77.56"/>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" vector-effect="non-scaling-stroke" cx="379.13" cy="1490.63" r="93.95"/>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" vector-effect="non-scaling-stroke" cx="379.13" cy="207.18" r="93.95"/>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" vector-effect="non-scaling-stroke" cx="848.9" cy="1490.63" r="93.95"/>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" vector-effect="non-scaling-stroke" cx="848.9" cy="207.18" r="93.95"/>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" vector-effect="non-scaling-stroke" cx="1318.68" cy="1490.63" r="93.95"/>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" vector-effect="non-scaling-stroke" cx="1318.68" cy="207.18" r="93.95"/>
    <rect fill="none" stroke="#373435" stroke-width="1" vector-effect="non-scaling-stroke" x="0" y="0" width="1691.18" height="1691.18"/>
  </g>
</svg>
`;
export default Bloco14;
