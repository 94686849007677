const Bloco15 = `
<svg viewBox="-2 -9.33 37.72 37.72">
  <g>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" vector-effect="non-scaling-stroke" cx="2.25" cy="2.25" r="1.47"/>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" vector-effect="non-scaling-stroke" transform="matrix(3.2083E-14 -1.21139 1.21139 3.2083E-14 9.58116 9.58116)" r="1.21"/>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" vector-effect="non-scaling-stroke" cx="9.58" cy="2.25" r="1.47"/>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" vector-effect="non-scaling-stroke" transform="matrix(3.2252E-14 -1.21139 1.21139 3.2252E-14 16.9115 9.58117)" r="1.21"/>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" vector-effect="non-scaling-stroke" transform="matrix(3.2083E-14 1.21139 -1.21139 3.2083E-14 2.25082 9.58116)" r="1.21"/>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" vector-effect="non-scaling-stroke" cx="16.91" cy="2.25" r="1.47"/>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" vector-effect="non-scaling-stroke" transform="matrix(3.2083E-14 -1.21139 1.21139 3.2083E-14 9.58116 16.9115)" r="1.21"/>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" vector-effect="non-scaling-stroke" transform="matrix(3.2252E-14 -1.21139 1.21139 3.2252E-14 16.9115 16.9115)" r="1.21"/>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" vector-effect="non-scaling-stroke" transform="matrix(3.2083E-14 1.21139 -1.21139 3.2083E-14 2.25082 16.9115)" r="1.21"/>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" vector-effect="non-scaling-stroke" transform="matrix(3.2083E-14 -1.21139 1.21139 3.2083E-14 24.2418 9.58117)" r="1.21"/>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" vector-effect="non-scaling-stroke" cx="24.24" cy="2.25" r="1.47"/>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" vector-effect="non-scaling-stroke" transform="matrix(3.2252E-14 -1.21139 1.21139 3.2252E-14 31.5722 9.58119)" r="1.21"/>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" vector-effect="non-scaling-stroke" cx="31.57" cy="2.25" r="1.47"/>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" vector-effect="non-scaling-stroke" transform="matrix(3.2083E-14 -1.21139 1.21139 3.2083E-14 24.2418 16.9115)" r="1.21"/>
    <circle fill="#AFAAA8" stroke="#201E1E" stroke-width="1" vector-effect="non-scaling-stroke" transform="matrix(3.2252E-14 -1.21139 1.21139 3.2252E-14 31.5722 16.9115)" r="1.21"/>
    <rect fill="none" stroke="#373435" stroke-width="1" vector-effect="non-scaling-stroke" width="33.72" height="19.06"/>
  </g>
</svg>
`;
export default Bloco15;
